export const initialValue={
    isUserLoggedIn: false,
    userData: [],
    personalInfo: [],
    sideBarName: "/",
    sideNavName: "",
    cardNavName: "",
    leavingPage: false,
    isUserSignedUp: false,
    isUserLogging: false,
    isExamStarted: false,
    isSideBarVisible:false,
    internalNavIndex:0,
    studentOnTelegram:[]
  }