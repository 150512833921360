import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { base_url } from "./noteSlice";
import { initialValue } from "../../assets/constant/initial_value";
import { api } from "../../config/api";

export const signUp = createAsyncThunk(
  "signUp",
  async ({ newUserData, toast, navigate }) => {
    try {
      const response = await api.post(`/signUp`, newUserData);
      toast.success("your account created");
      navigate("/signIn");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const signIn = createAsyncThunk(
  "login",
  async ({ userData, toast, navigate }) => {
    try {
      const response = await api.post(`/signIn`, userData);
      toast.success("Welcome back");
      navigate("/");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async ({ userData, navigate, toast }) => {
    try {
      const response = await axios.put(
        `${base_url}/update_password/${userData?.id}`,
        userData
      );
      toast.success("your password is rested");
      navigate("/signIn");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const sendToEmail = createAsyncThunk(
  "user/sendToEmail",
  async ({ userData, navigate, toast }) => {
    console.log(userData);
    try {
      const response = await axios.post(`${base_url}/send_email`, userData);
      toast.success("email confirmed");
      navigate(`/rest_password/${response.data}`);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }
);

export const getPersonalInfo = createAsyncThunk(
  "getPersonalInfo",
  async ({ id }) => {
    try {
      const response = await axios.get(`${base_url}/get_personal_info/${id}`);
      return response.data;
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  }
);

export const verifyPhoneNumber = createAsyncThunk(
  "verifyPhoneNumber",
  async ({ phoneData,toast,navigate}) => {
    try {
      const response = await axios.post(`${base_url}/verify`,phoneData);
      toast.success('Your phoneNumber is verified')
      navigate(`/rest_password/${response?.data?.id}`)
      return response.data;
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  }
);

export const updatePersonalInfo = createAsyncThunk(
  "updatePersonalInfo",
  async ({userData,toast }) => {
    try {
      const response = await axios.put(
        `${base_url}/update_personal_info`,
        userData
      );
      toast.success("your personal info is updated");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);


export const restPassword = createAsyncThunk(
  "restPassword",
  async ({ userData,id, navigate, toast }) => {
    try {
      const response = await axios.put(`${base_url}/rest_password/${id}`,userData);
      toast.success("your password is rested");
      navigate("/signIn");
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);


export const getUserByTelegramId=createAsyncThunk('getUserByTelegramId',async({telegramId})=>{
  try {
      const response= await api.post(`/get_student_by_bot_id?id=${telegramId}`)
      return response.data
  } catch (error) {
     console.log(error)
  }
})

const studentSlice = createSlice({
  name: "student",
  initialState: initialValue,
  reducers: {
    logOut: (state, action) => {
      state.isUserLoggedIn = false;
      state.personalInfo=[]
      state.sideBarName='/personal'
      localStorage.removeItem('student')
    },
    setSideBar: (state, action) => {
      state.sideBarName = action.payload;
    },
    setNavBar: (state, action) => {
      state.sideNavName = action.payload;
    },
    setCardNav: (state, action) => {
      state.cardNavName = action.payload;
    },
    setExamStart: (state, action) => {
      state.isExamStarted = action.payload;
    },
    setLeavingPage: (state, action) => {
      state.leavingPage = action.payload;
    },
    setSideBarVisible: (state, action) => {
      state.isSideBarVisible =!state.isSideBarVisible;
    },
     setInternalNavIndex:(state,action)=>{
      state.internalNavIndex=action.payload
    }
  },
  extraReducers: {
    [signIn.pending]: (state, action) => {
      state.isUserLoggedIn = false;
      state.isUserLogging = true;
    },
    [signIn.fulfilled]: (state, action) => {
        state.isUserLoggedIn = true;
        state.isUserLogging = false;
        state.personalInfo=action.payload
        localStorage.setItem('student',JSON.stringify(action.payload))
      },
    [signIn.rejected]: (state, action) => {
      state.isUserLoggedIn = false;
      state.isUserLogging = false;
    },

    [signUp.pending]: (state, action) => {
      state.isUserLoggedIn = false;
      state.isUserSignedUp = true;
    },
    [signUp.fulfilled]: (state, action) => {
      state.isUserLoggedIn = false;
      state.isUserSignedUp = false;
    },

    [signUp.rejected]: (state, action) => {
      state.isUserLoggedIn = false;
      state.isUserSignedUp = false;
    },

    [sendToEmail.pending]: (state, action) => {
      state.isUserLogging = true;
    },
    [sendToEmail.fulfilled]: (state, action) => {
      state.isUserLogging = false;
    },

    [sendToEmail.rejected]: (state, action) => {
      state.isUserLogging = false;
    },

    [updatePassword.pending]: (state, action) => {
      state.isUserLogging = true;
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.isUserLogging = false;
    },

    [updatePassword.rejected]: (state, action) => {
      state.isUserLogging = false;
    },

    [getPersonalInfo.pending]: (state, action) => {
      state.personalInfo = [];
    },
    [getPersonalInfo.fulfilled]: (state, action) => {
      state.personalInfo = action.payload;
    },
    [getPersonalInfo.rejected]: (state, action) => {
      state.personalInfo = [];
    },

    [getUserByTelegramId.pending]: (state, action) => {
      state.isLoading =true;
    },
    [getUserByTelegramId.fulfilled]: (state, action) => {
      state.studentOnTelegram = action.payload;
      state.isLoading=false
    },
    [getUserByTelegramId.rejected]: (state, action) => {
      state.isLoading =false;
    },
  },
});

export const {
  logOut,
  setSideBar,
  setCardNav,
  setExamStart,
  setLeavingPage,
  setNavBar,
  setSideBarVisible,
  setInternalNavIndex
} = studentSlice.actions;

export const studentReducer = studentSlice.reducer;
