import axios from "axios";


export const api=axios.create({
    baseURL:'https://yama.baryon.ras-school.com',
    // baseURL:'https://ethio-exam-api.onrender.com',
    // baseURL:'http://localhost:4400'
})

export const rasMeetingUrl='https://ras-school-meeting.netlify.app/login'
export const base_url='https://yama.baryon.ras-school.com'
// export const base_url='https://ethio-exam-api.onrender.com'
// export const base_url='http://localhost:4000'
